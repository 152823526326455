import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslations from "./translations/en.json";
import koTranslations from "./translations/ko.json";

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: { translation: enTranslations },
            ko: { translation: koTranslations },
        },
        fallbackLng: "ko",
        supportedLngs: ["en", "ko"],
        detection: {
            order: ["cookie", "localStorage", "navigator", "htmlTag"],
            caches: ["cookie"],
        },
        react: { useSuspense: false },
    });

export default i18n;
